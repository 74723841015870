import { getAllDict } from '@/api/user'
export const Mixin = {
  data() {
    return {
      dataSourcesOpt: [
        {
          label: '万邑通', // 生效
          val: 1
        }, {
          label: '退货入库', // 生效
          val: 2
        }
      ],
      calcStatus: [
        {
          label: '成功', // 生效
          val: 1
        }, {
          label: '失败', // 生效
          val: 0
        }
      ],
      // 入库方式
      receiveTypeOpt: [
        {
          label: '先入库再上架', // 生效
          val: 1
        }, {
          label: '入库上架同时', // 生效
          val: 2
        }, {
          label: 'PC快速入库', // 生效
          val: 3
        }
      ],
      CollectTaskStatusOpt: [
        {
          label: '生效', // 生效
          val: 1
        }, {
          label: '收货中', // 生效
          val: 2
        }, {
          label: '收货完成', // 生效
          val: 3
        }, {
          label: '部分收货', // 生效
          val: 4
        }
      ],
      warehouseType: [
        {
          label: '公司仓', // 已生效
          val: 1
        }, {
          label: '供应商仓库', // 待出库
          val: 2
        }, {
          label: '自管仓', // 待出库
          val: 3
        }, {
          label: '第三方仓', // 已复核
          val: 4
        }, {
          label: '平台仓', // 已出库
          val: 5
        }, {
          label: '工厂仓', // 终止发货
          val: 6
        }
      ],
      businessType: [
        {
          label: '其他入库',
          val: 'INVOICING_OTHER_IN'
        }, {
          label: '其他出库',
          val: 'INVOICING_OTHER_OUT'
        }, {
          label: '采购入库',
          val: 'INVOICING_PURCHASE_IN'
        }, {
          label: '采购退货',
          val: 'INVOICING_PURCHASE_RETURN'
        }, {
          label: '退货入库',
          val: 'INVOICING_RETURN_IN'
        }, {
          label: '销售出库',
          val: 'INVOICING_SELL_OUT'
        }, {
          label: '盘亏出库',
          val: 'INVOICING_CHECK_DEFICIT'
        }, {
          label: '盘盈入库',
          val: 'INVOICING_CHECK_FULL'
        }, {
          label: '内购出库',
          val: 'INVOICING_INNER_OUT'
        }, {
          label: '报废出库',
          val: 'INVOICING_SCRAP_OUT'
        }, {
          label: '调拨出库',
          val: 'INVOICING_TRANSFER_OUT'
        }, {
          label: '调拨入库',
          val: 'INVOICING_TRANSFER_IN'
        }
      ],
      Dictionaries: {}
    }
  },
  mounted() {
    if (Object.keys(this.Dictionaries).length) return
    if (sessionStorage.getItem('Dictionaries')) {
      this.Dictionaries = JSON.parse(sessionStorage.getItem('Dictionaries') || '[]')
    } else {
      this.getDictionaries()
    }
  },
  computed: {

    serviceStatusOpt() {
      return this.getDictionariesOpt('VWMS_SERVICE_PROVIDER') // 服务商
    },
    outboundStatusOpt() {
      return this.getDictionariesOpt('VWMS_OUTBOUND_STATUS') // 出库状态
    },
    outboundTypeOpt() {
      return this.getDictionariesOpt('VWMS_BOUND_TYPE') // 出库类型
    },
    transportOpt() {
      return this.getDictionariesOpt('VWMS_TRANSPORT_STATUS') // 发运状态
    },
    shipmentOpt() {
      return this.getDictionariesOpt('VWMS_SHIPMENT_STATUS') // shipment状态
    },
    depositStatusOpt() {
      return this.getDictionariesOpt('VWMS_DEPOSIT_TYPE')// 入库类型
    },
    collectStatusOpt() {
      return this.getDictionariesOpt('VWMS_COLLECT_STATUS')// 收货状态
    },
    trayStatusOpt() {
      return this.getDictionariesOpt('VWMS_TRAY_STATUS') // 入库状态
    },
    shelfStatusOpt() {
      return this.getDictionariesOpt('VWMS_SHELF_STATUS') // 上架状态
    },
    abnormalOpt() {
      return this.getDictionariesOpt('VWMS_OUT_ABNORMAL') // 出库差异
    },
    abnormalTypeOpt() {
      return this.getDictionariesOpt('VWMS_ABNORMAL_TYPE') // 差异类型
    },
    abnormalStatusOpt() {
      return this.getDictionariesOpt('VWMS_ABNORMAL_STATUS') // 差异状态
    },
    processResultOpt() {
      return this.getDictionariesOpt('VWMS_ABNORMAL_REASON') // 差异原因
    },
    transferTypeOpt() {
      return this.getDictionariesOpt('VWMS_TRANSFER_TYPE') // 调拨类型
    }
  },
  methods: {
    getServiceLabel(type) {
      for (const v of this.serviceStatusOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getTransportLabel(type) {
      for (const v of this.transportOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getOutboundTypeLabel(type) {
      for (const v of this.outboundTypeOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getOutTransferTypeLabel(type) {
      for (const v of this.transferTypeOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getStatusLabel(status) {
      for (const v of this.outboundStatusOpt) {
        if (v.val === status) return v.label
      }
      return status
    },
    getShipmentLabel(type) {
      for (const v of this.shipmentOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getDepositLabel(status) {
      for (const v of this.depositStatusOpt) {
        if (v.val === status) return v.label
      }
      return status
    },
    getCollectStatusLabels(status) {
      for (const v of this.collectStatusOpt) {
        if (v.val === status) return v.label
      }
      return status
    },
    getShelfStatusLabel(status) {
      for (const v of this.shelfStatusOpt) {
        if (v.val === status) return v.label
      }
      return status
    },
    getCollectTaskLabel(type) {
      for (const v of this.CollectTaskStatusOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getTrayStatusLabel(type) {
      for (const v of this.trayStatusOpt) {
        if (v.val === type) return v.label
      }
      return type
    },

    abnormalLabel(type) {
      for (const v of this.abnormalOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    abnormalTypeLabel(type) {
      for (const v of this.abnormalTypeOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    abnormalStatusLabel(type) {
      for (const v of this.abnormalStatusOpt) {
        if (+v.val === type) return v.label
      }
      return type
    },
    processResultLabel(type) {
      for (const v of this.processResultOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getDataSourcesLabel(type) {
      for (const v of this.dataSourcesOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getCalcStatusLabel(type) {
      for (const v of this.calcStatus) {
        if (v.val === type) return v.label
      }
      return type
    },
    getReceiveTypeLabel(type) {
      for (const v of this.receiveTypeOpt) {
        if (v.val === +type) return v.label
      }
      return type
    },
    getBusinessTypeLabel(type) {
      for (const v of this.businessType) {
        if (v.val === type) return v.label
      }
      return type
    },
    getWarehouseTypeLabel(type) {
      for (const v of this.warehouseType) {
        if (v.val === +type) return v.label
      }
      return type
    },

    getDictionariesOpt(key) {
      if (!key) return []
      const LangObj = {
        'zh': 'zh-CN',
        'en': 'en-US'
      }
      const langKey = LangObj[this.$i18n.locale]
      const Arr = []
      this.Dictionaries[key] && this.Dictionaries[key].map(v => {
        if (v.dictName) Arr.push({ label: JSON.parse(v.dictName)[langKey], val: v.dictValue, id: v.id })
      })
      return Arr
    },
    async getDictionaries() {
      const { datas } = await getAllDict()
      if (Object.keys(datas).length) {
        this.Dictionaries = datas
        console.log(datas)
        sessionStorage.setItem('Dictionaries', JSON.stringify(datas))
      }
    }
  }
}

// Style信息查询
import request from '@/utils/request'
import { getLoginAddress } from '@/utils/auth'

export function getPageShipment(params) {
  return request({
    url: `outbound/pageShipment`,
    method: 'get',
    params
  })
}

export function downPortMark(data) {
  return request({
    url: `outbound/downPortMark`,
    method: 'post',
    data
  })
}

// shipment/发运明细查询
export function findTransportShipmentDetail(data) {
  return request({
    url: `outbound/findTransportShipmentDetail`,
    method: 'post',
    data
  })
}

// 发运管理分页查询
export function getPageTransport(params) {
  return request({
    url: `outbound/pageTransport`,
    method: 'get',
    params
  })
}

// 出库管理分页查询
export function getPageOutboundTask(params) {
  return request({
    url: `outbound/pageOutboundTask`,
    method: 'get',
    params
  })
}

// 出库管理分页查询
export function getFindOutboundDetail(data) {
  return request({
    url: `outbound/findOutboundDetail`,
    method: 'POST',
    data
  })
}

// 出库单地址查询
export function getFindOutboundAddress(data) {
  return request({
    url: `outbound/findOutboundAddress`,
    method: 'POST',
    data
  })
}

// 生成shipment
export function addShipment(data) {
  return request({
    url: `outbound/addShipment`,
    method: 'POST',
    data
  })
}

// 终止出库单
export function getTerminationOutbound(data) {
  return request({
    url: `outbound/terminationOutbound`,
    method: 'POST',
    data
  })
}

// 出库单发运信息-导入
export function outboundShipmentImport(data) {
  return request({
    url: `outbound/outboundShipmentImport`,
    method: 'POST',
    data,
    timeout: 999999
  })
}

// 出库单-导入
export function outboundImport(data) {
  return request({
    url: `outbound/outboundImport`,
    method: 'POST',
    data
  })
}

// 手动刷shipment计划-工具
export function createShipmentPlanTool(data) {
  return request({
    url: `shipment/createShipmentPlanTool`,
    method: 'POST',
    data
  })
}
// 查询出库单明细和亚马逊明细对比
export function findOutboundAmazonDetail(data) {
  return request({
    url: `outbound/findOutboundAmazonDetail`,
    method: 'POST',
    data
  })
}

// 添加亚马逊shipment明细
export function addAmazonShipmentIdDetail(data) {
  return request({
    url: `outbound/addAmazonShipmentIdDetail`,
    method: 'POST',
    data
  })
}

/** 模板下载 */
export function findDownloadUrl(templateKey) {
  return request({
    url: `${getLoginAddress()}base/templatedownloadtask/findDownloadUrl/${templateKey}`,
    method: 'get'
  })
}

/* ---- */
// shipment 自动刷港口 获取站点对应的港口
export function getCenterId(siteCode) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/getCenterId/${siteCode}`,
    method: 'get'
  })
}

// 开始->根据港口自动创建shipment计划
export function startAutoCreateShipmentPlan(data) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/startAutoCreateShipmentPlan`,
    method: 'post',
    data
  })
}

// 停止->根据港口自动创建shipment计划
export function stopAutoCreateShipmentPlan(outboundCode, data) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/stopAutoCreateShipmentPlan/${outboundCode}`,
    method: 'post',
    data
  })
}

// 出库单信息查询
export function findOutbound(outboundCode) {
  return request({
    url: `${getLoginAddress()}wms-out2b/outbound/findOutbound/${outboundCode}`,
    method: 'get'
  })
}

// 亚马逊创建shipment计划
export function createShipmentPlan(outboundCode) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/createShipmentPlan/${outboundCode}`,
    method: 'get'
  })
}

/**
 * @description 根据计划 shipment生成
 * @param shipmentId {String} shipment id
 * @param sendSiteCode {String} 站点
 * @param outboundCode {String} 出库单号
 * @param outboundWarehouseId {Number} 出货仓库
 */
export function createShipmentByPlan(data) {
  return request({
    url: `/wms-out2b/shipmentcreateShipment`,
    method: 'post',
    data
  })
}

// 查shipment计划
export function findShipmentPlan(outboundCode) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/findShipmentPlan/${outboundCode}`,
    method: 'get'
  })
}

// 出库单号查询明细统计
export function queryOutboundDetailTotal(outboundCode) {
  return request({
    url: `${getLoginAddress()}wms-out2b/outbounddetail/getOutboundCodeTotal/${outboundCode}`,
    method: 'get'
  })
}

/**
 * @description 查询出库明细和亚马逊数量
 * @param outboundCode {String} 出库单号
 * @param sendSiteCode {String}
 * @param shipmentId {String}
 */
export function findDetailAndMws(data) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/findDetailAndMws`,
    method: 'post',
    data
  })
}

// shipmentID信息查询
export function findShipmentId(shipmentId) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/findShipmentId/${shipmentId}`,
    method: 'get'
  })
}

/**
 * @description 手动输入shipment生成
 * @param sdList {Array} shipment明细
 * @param shipment {Object} shipment信息
 */
export function createShipmentManual(data) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/createShipmentManual`,
    method: 'post',
    data
  })
}
/* ---- */

// 平台信息查询
export function queryTerraceList(params) {
  return request({
    url: `${getLoginAddress()}base/baseterrace/list`,
    method: 'get',
    params
  })
}

// 站点条件查询 只查询平台下绑定了站点的
export function querySiteList(params) {
  return request({
    url: `${getLoginAddress()}base/basesite/listByPlatform`,
    method: 'get',
    params
  })
}

// 店铺条件查询
export function getShopList(params) {
  return request({
    url: `${getLoginAddress()}base/platformsiteshop/list`,
    method: 'get',
    params
  })
}
// 门店根据出库单生成shipment-新的
export function insterShipmentAndPick(data) {
  return request({
    url: `${getLoginAddress()}wms-out2b/shipment/insterShipmentAndPick`,
    method: 'post',
    data
  })
}

// 渠道明细
export function getChannelItem(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}channel/item`,
    method: 'get',
    params
  })
}

// 渠道信息
export function gitChannel(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}channel/list`,
    method: 'get',
    params
  })
}

// 查询所有站点
export function queryAllSiteList(params) {
  return request({
    url: `${getLoginAddress()}base/basesite/list`,
    method: 'get',
    params
  })
}

export function manualProfitAndLoss(data) {
  return request({
    url: `outbound/manualProfitAndLoss`,
    method: 'post',
    data
  })
}
